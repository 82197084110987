import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled, { css } from "styled-components";
import ty from "../styled-tachyons";
import { navigate } from "gatsby-link";
import Layout from "../components/Layout";
import {
  DarkGraySection,
  PageHeader,
  Button,
  BodyText,
  SectionHeader,
  SubHeader,
  rem
} from "../styles";
import MetaTags from "../components/MetaTags";
import * as queryString from "query-string";
/** Stripe **/
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

const inputStyles = css`
  ${ty`b--red ba br4 bg-transparent pv2 mb3`};
  &,&::placeholder {
    ${ty`tc white`};
  }

  &:focus {
    outline: none;
    ${ty`b--white`}
    &::placeholder {
      ${ty`tc white near-black`}
    }
  }
`;

const NameInput = styled.input`
  ${inputStyles}
`;
const EmailInput = styled.input.attrs({
  type: "email",
})`
  ${inputStyles}
`;
const PhoneInput = styled.input.attrs({
  type: "tel",
})`
  ${inputStyles}
`;
const AmountInput = styled.input`
  ${inputStyles}
`;
const DescriptionInput = styled.textarea`
  ${inputStyles};
  ${ty`h4`};
  padding: ${rem(11)};
`;

const CardContainer = styled.div`
  ${inputStyles}
  padding: 0.8rem
`;
const CardInput = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: '500',
      fontFamily: 'Prompt, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#fff',
      },
    }
  }
}

const DonateForm = styled(({ amount, description, placeholder, className }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardEmpty, setCardEmpty] = useState(true);
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);

  const required = (value) => {
    if (value === "" || value === 0) {
      setError('All fields are required.')
      return false;
    }
    return true;
  }

  const isCardEmpty = (value) => {
    if (value === true) {
      setError('Credit card number is required.')
      return false;
    }
    return true;
  }

  const validEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (required(email) && !regex.test(email)) {
      setError('Email must be a valid email.')
      return false;
    }
    return true;
  }

  const validAmount = (amount) => {
    const regex = /^\d+\.\d{0,2}$/;
    if (required(amount) && !regex.test(amount)) {
      setError('Amount must be in decimal.')
      return false;
    }
    return true;
  }

  const handleChange = async (e) => {
    // Listen for changes in the CardElement 
    // and display any errors as the customer types their card details
    setCardEmpty(e.empty)
    setError(e.error ? e.error.message : '');
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    
    // get form data
    const form = e.target;
    const values = {
      name: form.elements.name.value,
      email: form.elements.email.value.toLowerCase(),
      phone: form.elements.phone.value,
      amount: form.elements.amount.value,
      description: form.elements.description.value
    };

    try {
      if (required(values.name) &&
        validEmail(values.email) &&
        required(values.phone) &&
        validAmount(values.amount) &&
        isCardEmpty(cardEmpty)) {

        // reset error
        setError('');
        // set processing
        setProcessing(true)

        // setup stripe client secret
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...values,
            amount: Math.round(values.amount * 100),
            description: (values.description) ? values.description : `$${values.amount} donation`
          }) // amount must be in cents
        };

        fetch('/.netlify/functions/create-payment-intent', requestOptions)
          .then(res => {
            return res.json();
          })
          .then(data => {
            const { client_secret } = data.paymentIntent;
            stripe.confirmCardPayment(client_secret, {
              payment_method: {
                card: elements.getElement(CardElement)
              }
            }).then(res => {
              
              if (res.error) {
                setProcessing(false)
                setError(`Payment failed ${res.error.message}`);
              } else {
                setError('');
                navigate(form.getAttribute("action"));
              }
            });
          });
      }
    } catch (e) {
      alert(e);
    }
  };

  return (
    <>
      <SubHeader css={ty`red`}>{error}</SubHeader>
      <form
        className={className}
        onSubmit={onSubmit}
        name="donate"
        method="post"
        action="/donate/thanks/"
      >
        <NameInput placeholder="Name" id="name" name="name" required />
        <EmailInput placeholder="Email" id="email" name="email" required />
        <PhoneInput placeholder="Phone" id="phone" name="phone" required />
        <AmountInput
          id="amount" name="amount"
          defaultValue={(amount) ? amount : ""}
          placeholder={placeholder}
          required
        />
        <CardContainer>
          <CardElement id="card-element" options={CardInput} onChange={handleChange} />
        </CardContainer>
        <DescriptionInput
          id="description"
          name="description"
          placeholder="Use this field if you would like to request that your donation goes to someone or something specific."
          hidden={!description}
        />
        <Button
          as="button"
          ty="self-center ph5"
          type="submit"
          disabled={processing}
        >
          {processing ? 'Processing...' : 'Donate'}
        </Button>
      </form>
    </>
  );
})`
  ${ty`flex flex-column mw6 center ph3`}
`;

const DonatePageStyle = styled(DarkGraySection)`
  ${SectionHeader} {
    ${ty`mb2 mt5 f3 tracked fw7`}
  }
  ${DonateForm} {
    ${ty`pt1`}
  }
`;

const ThankYouSection = styled(BodyText)`
  ${inputStyles}
  ${ty`mw6 f3 pv5 mt5 fw7`}
`;

export const DonatePageTemplate = ({ submitted, title, level, levels }) => {

  let content;
  if (submitted) {
    content = (
      <ThankYouSection fontSize="large">
        Thank you so much for the support!
      </ThankYouSection>
    );
  } else {
    const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
    const {
      description,
      heading,
      subheading,
      amount
    } = (level === undefined) ? levels[0] : levels.filter(n => n.name === level)[0]
    const placeholder = (subheading) ? `Amount between ${subheading}` : 'Amount'

    content = (
      <Elements stripe={stripePromise}>
        <BodyText>
          Please fill out all fields in the form below to complete your donation.<br />
          Thank you so much for the support!!
        </BodyText>
        <SectionHeader>
          {heading} <br />
          {subheading}
        </SectionHeader>
        <DonateForm amount={amount} description={description} placeholder={placeholder} />
      </Elements>
    );
  }

  return (
    <>
      <MetaTags title={title} />
      <DonatePageStyle>
        <PageHeader
          css={`
            ${ty`tc`}
          `}
        >
          Donate
        </PageHeader>
        {content}
      </DonatePageStyle>
    </>
  );
};

DonatePageTemplate.propTypes = {};

const DonatePage = ({ data, location }) => {
  const { level } = queryString.parse(location.search);

  return (
    <Layout>
      <DonatePageTemplate {...data.markdownRemark.frontmatter} level={level} />
    </Layout>
  );
};

DonatePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DonatePage;

export const pageQuery = graphql`
  query DonatePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "donate-page" } }) {
      frontmatter {
        title
        
        levels {
          name
          heading
          subheading
          amount
          description
        }
      }
    }
  }
`;
